import React from 'react';
import PropTypes from 'prop-types';

import { getLabelCheckboxItem } from '../../../../bi/utils/insurance';

import { LABELS_RATES } from '../../../../bi/constants/insurance';
import { SERVICETYPERU } from '../../../../bi/constants/serviceType';

import styles from './styles/index.module.css';

const LABELS = {
  INSURANCE_TITLE: 'Страхование услуг на отмену поездки',
  RATE: 'Тариф:',
};

const InsuranceItem = ({
  item: {
    Rate,
    InsuranceServices,
  },
}) => {
  const renderRate = () => {
    const upperRateName = Rate.toUpperCase();

    return <h3>{ LABELS.RATE } { LABELS_RATES[upperRateName] }</h3>;
  };

  const renderInsuranceServices = () => InsuranceServices && InsuranceServices.map((service) => {
    const { Data, Amount, ServiceType } = service;

    const infoAboutService = getLabelCheckboxItem(Data, ServiceType);

    return <p>{ SERVICETYPERU[ServiceType] }: { infoAboutService }, { Amount } ₽</p>;
  });

  return (
    <div className={ styles.item }>
      <div className={ styles.name }>
        <h3>{ LABELS.INSURANCE_TITLE }</h3>
        { renderRate() }
      </div>
      { renderInsuranceServices() }
    </div>
  );
};

InsuranceItem.propTypes = {
  item: PropTypes.object.isRequired,
};

export { InsuranceItem };
