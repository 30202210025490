import React from 'react';
import PropTypes from 'prop-types';

import AirlineItem from './AirlineItem';
import HotelItem from './HotelItem';
import TransferItem from './TransferItem';
import TrainItem from './TrainItem';
import ItemPanel from './ItemPanel';
import ItemLayout from './ItemLayout';
import EmployeeAir from './employeeAir';
import Timer from './Timer';

import documentType from '../../../bi/constants/documentType';
import { SERVICETYPE } from '../../../bi/constants/serviceType';

import styles from '../styles/note.module.scss';

const NoteItem = (props) => {
  const {
    item,
    foul,
    serverTime,
  } = props;

  const itemData = JSON.parse(item.Data);
  const isReserved = !foul && item.IsReserved ? styles['reserved-line'] : '';

  const isEmployeeAir = !foul
    && item.IsReserved
    && (item.ServiceType === SERVICETYPE.AIR || item.ServiceType === SERVICETYPE.HOTEL);

  const employeeHtml = isEmployeeAir ? (
    <EmployeeAir
      item={ item }
      documentType={ documentType }
    />
  ) : null;

  const renderTimer = () => {
    if (!isReserved) {
      return null;
    }

    return (
      <div className={ styles['note-timer'] }>
        <Timer
          deadLineInStrUTC={ item.BookDeadline }
          currentTime={ serverTime }
        />
      </div>
    );
  };

  const renderPrice = () => {
    switch (item.ServiceType) {
      case SERVICETYPE.AIR: {
        return itemData.Price.TotalPrice;
      }
      case SERVICETYPE.HOTEL: {
        return itemData.room.Price.TotalPrice;
      }
      case SERVICETYPE.TRANSFER: {
        return itemData.Price.TotalPrice;
      }
    }

    return null;
  };

  const renderItem = () => {
    const itemMetadata = JSON.parse(item.Metadata) || {};
    const PNR = itemMetadata.PNR;

    switch (item.ServiceType) {
      case SERVICETYPE.AIR: {
        return itemData.Routes.map(route =>
          (
            <AirlineItem
              key={ route.ID }
              providerName={ itemData.ProviderName }
              route={ route }
              pnr={ PNR }
              meta={ itemData.Metadata }
              showSegments
            />
          )
        );
      }
      case SERVICETYPE.HOTEL: {
        return (
          <HotelItem
            item={ itemData }
          />
        );
      }
      case SERVICETYPE.TRANSFER: {
        return (
          <TransferItem
            item={ itemData }
          />
        );
      }
      case SERVICETYPE.TRAIN: {
        return (
          <TrainItem
            item={ itemData }
          />
        );
      }
    }

    return null;
  };

  return (
    <ItemPanel className={ `${styles.note_item} ${foul ? styles.foul : ''}` }>
      <div className={ `${styles.item} ${isReserved}` }>
        <ItemLayout
          price={ renderPrice() }
          serviceType={ item.ServiceType }
        >
          { renderItem() }
          { employeeHtml }
        </ItemLayout>
        { renderTimer() }
      </div>
    </ItemPanel>
  );
};

NoteItem.propTypes = {
  item: PropTypes.object.isRequired,
  foul: PropTypes.bool,
  serverTime: PropTypes.object.isRequired,
};

NoteItem.defaultProps = {
  foul: false,
};

export default NoteItem;
