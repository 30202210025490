import React, { Component } from 'react';
import PropTypes from 'prop-types';

import EmployeePassport from '../EmployeePassport';

import styles from './styles/index.module.css';

const getDocuments = (item) => {
  const documents = [];
  let currentDocumentId = null;

  if (item && item.Documents) {
    const { Employee, Documents } = item;
    Employee.Documents.forEach((e) => {
      if (Documents.AllowedDocumentTypes.includes(e.Type)) {
        documents.push(e);
      }

      if (e.Selected) {
        currentDocumentId = e.Id;
      }
    });
  }

  return {
    documents,
    currentDocumentId,
  };
};

class EmployeeItem extends Component {
  static propTypes = {
    number: PropTypes.number.isRequired,
    index: PropTypes.number.isRequired,
    item: PropTypes.object,
    items: PropTypes.array.isRequired,
    enumOfDocuments: PropTypes.object.isRequired,
    employeesWithCompanies: PropTypes.array.isRequired,
    showDocument: PropTypes.bool.isRequired,
    readOnly: PropTypes.bool.isRequired,
    isCart: PropTypes.bool.isRequired,
    isMulti: PropTypes.bool.isRequired,
    companies: PropTypes.array.isRequired,
    projects: PropTypes.array.isRequired,
  };

  static defaultProps = {
    item: null,
  };

  constructor(props) {
    super(props);

    const { documents, currentDocumentId } = getDocuments(props.item);
    const employee = props.item ? props.item.Employee : null;

    let value = 0;

    if (employee) {
      value = props.employeesWithCompanies.find(emply => emply.EmployeeId === employee.Id).ProjectId;
    }

    this.state = {
      inputValue: '',
      projectValue: value || 0,
      employee,
      currentDocumentId,
      documents,
      companies: props.companies,
    };
  }

  handleGetValue = (suggestion) => {
    if (!suggestion) return null;

    const patronymic = suggestion.Patronymic ? ` ${suggestion.Patronymic}` : '';

    return `${suggestion.Surname} ${suggestion.Name}${patronymic}`;
  };

  renderCompanies() {
    const { employee, companies } = this.state;
    const { items, index, readOnly } = this.props;
    if ((items.length > 1 && index > 0) || !employee) {
      return null;
    }

    if ((employee.Companies && employee.Companies.length === 1) || readOnly) {
      const companyNameForAloneCompany = companies.find(cmpn => cmpn.CompanyId === employee.Companies[0].CompanyId);
      const nameCompany = employee.Companies[0].ShortCompanyName || employee.Companies[0].CompanyName;

      return (
        <div className={ styles['employee-company'] }>
          <span className={ styles['employee-company-name'] }>
            { nameCompany || (companyNameForAloneCompany.ShortCompanyName || companyNameForAloneCompany.CompanyName) }
          </span>
        </div>
      );
    }

    return null;
  }

  renderProjects = () => {
    const { projectValue } = this.state;
    const { projects, readOnly } = this.props;

    const mapProjects = projects.map(project => ({
      label: `Центр затрат ${project.Name}`,
      value: project.Id,
    }));

    mapProjects.unshift({ label: 'Центр затрат не указан', value: 0 });

    const selectedProject = mapProjects.find(item => item.value === projectValue);
    const readonlyText = selectedProject ? selectedProject.label : 'Центр затрат не указан';

    const readonlyHtml = (
      <span>{ readonlyText }</span>
    );

    return (
      <div className={ styles['employee-projects'] }>
        { readOnly ? readonlyHtml : null }
      </div>
    );
  };

  renderItem() {
    const { employee, documents } = this.state;
    const { isCart, isMulti, showDocument, enumOfDocuments, projects } = this.props;

    const employeeHtml = this.handleGetValue(employee);

    let employeePassportHtml = null;

    if (showDocument) {
      employeePassportHtml = !documents.length ? (
        <div className={ styles['employee-document'] }>
          <div>
            <div className={ styles['employee-documents'] }>
              у сотрудника нет паспорта
            </div>
          </div>
        </div>
      ) : (
        <div className={ styles['employee-document'] }>
          <EmployeePassport
            employee={ employee }
            documents={ documents }
            enumOfDocuments={ enumOfDocuments }
          />
        </div>
      );
    }

    const projectHtml = projects.length && isCart ? this.renderProjects() : null;

    const selectsHtml = showDocument ? (
      <div className={ styles['employee-multi'] }>
        { this.renderCompanies() }
        { projectHtml }
      </div>
    ) : (
      <div className={ styles['employee-not-document'] }>
        { this.renderCompanies() }
        { projectHtml }
      </div>
    );

    return (
      <div className={ styles['employee-wrap'] }>
        <div className={ styles['employee-item'] }>
          <div className={ styles.employee }>
            { employeeHtml }
          </div>
          { employeePassportHtml }
          { isMulti ? selectsHtml : projectHtml }
        </div>
      </div>
    );
  }

  render() {
    const { number } = this.props;

    return (
      <div className={ styles.item }>
        <div className={ styles.number }>
          { number }.
        </div>
        { this.renderItem() }
      </div>
    );
  }
}

export default EmployeeItem;
