import React from 'react';
import PropTypes from 'prop-types';

import ProviderName from '../ProviderName/index';

import { formatDate } from '../../../../bi/utils/formatDate';

import { DEFAULTDATE, DEFAULTTIME } from '../../../../constants/time';

import { HotelCancellation } from './components/HotelCancellation';

import styles from './styles/index.module.css';

const LABELS = {
  EARLY_IN: value => `ранний заезд в ${value}`,
  LATE_OUT: value => `поздний выезд в ${value}`,
};

const MEAL = {
  HAS_MEAL: 'С завтраком',
  NOT_HAS_MEAL: 'Без завтрака',
  HAS_MEAL_NAME: 'Питание',
  NOT_HAS_MEAL_NAME: 'Без питания',
  MEAL_INCLUDED: 'Завтрак включен',
  MEAL_NO_INCLUDED: 'Завтрак не включен',
};

const HotelItem = ({
  item: {
    room: { Name, Amenities },
    hotel,
    Rate,
    Rate: {
      ProviderName: providerName,
    },
    checkin,
    checkout,
  },
}) => {
  const roomName = Name ? Name.trim().toLowerCase() : null;
  const cancellationInfo = Amenities.CancellationInfo;
  const cancellationDate = formatDate(cancellationInfo);
  const cancellationHtml = cancellationInfo ? `, бесплатная отмена бронирования до ${cancellationDate}` : ', тариф невозвратный';

  const getDateWithInterval = () => {
    const preparedCheckin = (() => {
      const isCustom = Rate && Rate.CustomCheckInDate;
      const newCheckin = isCustom ? Rate.CheckinDate : checkin;

      let checkInDate = formatDate(checkin, DEFAULTDATE);

      if (isCustom) {
        const checkInTime = formatDate(newCheckin, DEFAULTTIME);
        checkInDate += ` (${LABELS.EARLY_IN(checkInTime)})`;
      }

      return checkInDate;
    })();

    const preparedCheckout = (() => {
      const isCustom = Rate && Rate.CustomCheckOutDate;
      const newCheckout = isCustom ? Rate.CheckoutDate : checkout;

      let checkOutDate = formatDate(newCheckout, DEFAULTDATE);

      if (isCustom) {
        const checkOutTime = formatDate(newCheckout, DEFAULTTIME);
        checkOutDate += ` (${LABELS.LATE_OUT(checkOutTime)})`;
      }

      return checkOutDate;
    })();

    return `${preparedCheckin} – ${preparedCheckout}`;
  };

  const getMealNameInfo = mealName => (mealName ? `${MEAL.HAS_MEAL_NAME}: ${mealName}` : MEAL.HAS_MEAL);

  const getNoMealNameInfo = mealName => (mealName ? MEAL.NOT_HAS_MEAL_NAME : MEAL.NOT_HAS_MEAL);

  const mealName = getMealNameInfo(Amenities.Meal.Name);
  const noMealName = getNoMealNameInfo(Amenities.Meal.Name);

  const hasMeal = Amenities.HasMeal;
  const mealTypeText = hasMeal ? mealName : noMealName;

  const dates = getDateWithInterval();

  return (
    <div className={ styles.item }>
      <div className={ styles.name }>
        <h3>{ hotel.Name }</h3>
      </div>
      <div className={ styles.address }>
        <div>{ hotel.City }, { hotel.Address }</div>
        <div className={ styles.date_interval }>{ dates }</div>
      </div>
      <div className={ styles.details }>
        <b>Номер:&nbsp;</b>
        { roomName }
        { cancellationHtml }
      </div>
      <div className={ styles.block }>
        { mealTypeText }
      </div>
      <HotelCancellation
        fromTrip={ false }
        cancellationPenalties={ Amenities.CancellationPenalties }
        cancellationDate={ cancellationDate }
        cancellationInfo={ Amenities.CancellationInfo }
      />
      <ProviderName providerName={ providerName } />
    </div>
  );
};

HotelItem.propTypes = {
  item: PropTypes.object.isRequired,
};

export default HotelItem;
