import React from 'react';
import PropTypes from 'prop-types';

import EmployeeItem from './item';

import styles from './styles/index.module.css';

const EmployeeList = ({ items,
  employeesWithCompanies,
  enumOfDocuments,
  isCart,
  showDocument,
  readOnly,
  companies,
  projects,
  isMulti }) => {
  const maxEmployees = items.length;

  return (
    <div className={ styles.wrap }>
      { Array(maxEmployees).fill(0).map((item, index) => (
        <EmployeeItem
          key={ `employee-${index}` }
          index={ index }
          items={ items }
          employeesWithCompanies={ employeesWithCompanies }
          item={ items[index] }
          number={ index + 1 }
          isCart={ isCart }
          isMulti={ isMulti }
          showDocument={ showDocument }
          readOnly={ readOnly }
          enumOfDocuments={ enumOfDocuments }
          companies={ companies }
          projects={ projects }
        />
      ))}
    </div>
  );
};

EmployeeList.propTypes = {
  items: PropTypes.array.isRequired,
  employeesWithCompanies: PropTypes.array.isRequired,
  enumOfDocuments: PropTypes.object.isRequired,
  isCart: PropTypes.bool,
  isMulti: PropTypes.bool,
  showDocument: PropTypes.bool.isRequired,
  readOnly: PropTypes.bool.isRequired,
  companies: PropTypes.array.isRequired,
  projects: PropTypes.array.isRequired,
};

EmployeeList.defaultProps = {
  isCart: false,
  isMulti: false,
};


export default EmployeeList;
