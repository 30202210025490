import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { rpad } from '../../../../bi/utils/pad';

import styles from './styles/time_input.module.css';

class TimeInput extends Component {
  constructor(props) {
    super();

    const part = props.value.split(':');
    const hours = part[0];
    const minutes = part[1];

    this.state = {
      hours, minutes,
    };
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    const part = nextProps.value.split(':');
    const hours = part[0];
    const minutes = part[1];

    this.setState({
      hours, minutes,
    });
  }

  handleBlur = () => {
    let { hours, minutes } = this.state;

    if (hours.length !== 2) hours = rpad(hours, 2);
    if (minutes.length !== 2) minutes = rpad(minutes, 2);

    if (this.props.onChange) {
      this.props.onChange(hours, minutes);
    } else if (this.props.onBlur) {
      this.props.onBlur(hours, minutes);
    } else {
      this.setState({
        hours, minutes,
      });
    }
  };

  handleKeyPressTimeInput = (e) => {
    const re = /^[0-9]+$/g;
    const key = e.key;

    if (key !== ':' && !re.test(key)) {
      e.preventDefault();
    }
  };

  handleChangeTimeInput = (e) => {
    let val = e.target.value;

    val = val.substring(0, 5).replace(/:/g, '');

    let hours = '';
    let minutes = '';
    switch (val.length) {
      case 4:
        hours = val.charAt(0) + val.charAt(1);
        minutes = val.charAt(2) + val.charAt(3);
        break;
      case 3:
        hours = val.charAt(0) + val.charAt(1);
        minutes = val.charAt(2);
        break;
      case 2:
        hours = val.charAt(0) + val.charAt(1);
        break;
      case 1:
        hours = val.charAt(0);
        break;
      default:
        hours = '';
        minutes = '';
        break;
    }

    if (hours > 24) hours = '00';
    if (minutes > 59) minutes = '00';

    if (this.props.onChange) {
      this.props.onChange(hours, minutes);
    } else {
      this.setState({
        hours, minutes,
      });
    }
  };

  render() {
    const { border, className } = this.props;
    const { hours, minutes } = this.state;

    const value = [];
    if (hours) {
      value.push(hours);

      if (minutes) {
        value.push(minutes);
      }
    }

    const classNameArray = [styles.time];
    if (border) {
      classNameArray.push(styles.border);
    }
    if (className) {
      classNameArray.push(className);
    }

    return (
      <input
        value={ value.join(':') }
        className={ classNameArray.join(' ') }
        onKeyPress={ this.handleKeyPressTimeInput }
        onChange={ this.handleChangeTimeInput }
        onBlur={ this.handleBlur }
      />
    );
  }
}

TimeInput.propTypes = {
  value: PropTypes.string.isRequired,
  border: PropTypes.bool,
  className: PropTypes.string,
  onChange: PropTypes.func,
  onBlur: PropTypes.func,
};

TimeInput.defaultProps = {
  border: false,
  className: '',
  onChange: () => {},
  onBlur: () => {},
};

export default TimeInput;
