import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';

import NoteItem from './noteItem';
import trimTimeZone from '../../../bi/utils/trimTimezone';

import styles from '../styles/index.module.scss';

const Note = (props) => {
  const { note } = props;
  const items = [];
  const fouls = [];

  note.Items.map((item) => {
    const bookDeadline = item.BookDeadline ? item.BookDeadline : '';
    const isFoul = item.IsReserved && moment(trimTimeZone(bookDeadline))
      .isBefore(moment(note.ServerTime));

    if (!isFoul) {
      items.push(
        <NoteItem
          key={ item.Id }
          item={ item }
          serverTime={ moment(note.ServerTime) }
        />
      );
    } else {
      fouls.push(
        <NoteItem
          key={ item.Id }
          item={ item }
          serverTime={ moment(note.ServerTime) }
          foul
        />
      );
    }

    return null;
  });

  const renderEmpty = () => (
    <div className={ `${styles.panel} ${styles.cart} ${styles.empty}` }>
      <div className={ styles['cart-name__empty'] }>Блокнот отсутствует</div>
    </div>
  );

  const html = note.Items.length ? (
    <div className={ `${styles.panel} ${styles.note}` }>
      <div>
        <div className={ styles.row }>
          <div className={ styles['cart-name__field'] }>Блокнот</div>
        </div>
        { items }
      </div>
      {
        fouls.length ? (
          <div>
            <div className={ styles.row } />
            <div className={ styles.row }>
              <div className={ styles['cart-name__field'] }>Неактуальные</div>
            </div>
            { fouls }
          </div>
        ) : ''
      }
    </div>
  ) : renderEmpty();

  return (
    <div className={ styles['carts-container'] }>
      { html }
    </div>
  );
};

Note.propTypes = {
  note: PropTypes.object.isRequired,
};

export default Note;
