import React from 'react';
import PropTypes from 'prop-types';

import MoneyFormat from '../../../../bi/utils/money';

import styles from './styles/itemLayout.module.css';

const SERVICESICON = {
  Air: 'smartway-airplane',
  Hotel: 'smartway-hotel',
  Transfer: 'smartway-transfer',
  Train: 'smartway-train',
  Custom: 'smartway-reports',
  AeroExpress: 'smartway-aeroexpress-temp-big',
};

const ItemLayout = ({
  price,
  serviceType,
  children,
  renderOption,
}) => {
  const service = SERVICESICON[serviceType];
  const priceHtml = price ? `${MoneyFormat.moneyWithDecimal(price)} р.` : null;

  const optionHtml = typeof renderOption === 'function'
    ? renderOption()
    : renderOption;

  return (
    <div className={ styles.wrap_layout }>
      <div className={ styles.icon }>
        <span className={ `${service}` } />
      </div>
      <div className={ styles.children }>
        { children }
      </div>
      <div className={ styles.actions }>
        <div className={ styles.price }>{ priceHtml }</div>
        { optionHtml }
      </div>
    </div>
  );
};

ItemLayout.propTypes = {
  price: PropTypes.number,
  serviceType: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
  renderOption: PropTypes.func,
};

ItemLayout.defaultProps = {
  price: null,
  renderOption: null,
};

export default ItemLayout;
