import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { definePassport } from '../../../bi/utils/employee';

import styles from '../styles/note.module.scss';

class EmployeeAir extends Component {
  static propTypes = {
    item: PropTypes.object.isRequired,
    documentType: PropTypes.object.isRequired,
  };

  renderEmployeeItem = () => {
    const { item, documentType } = this.props;

    return item.Employees.map((employeeItem, ind) => {
      const employee = employeeItem.Employee;
      const passport = definePassport(documentType, employeeItem.Employee, employeeItem.Documents);

      return (
        <div
          className={ styles['reserved-employee'] }
          key={ ind }
        >
          <div className={ styles['reserved-employee__name'] }>
            <div>
              { ind + 1 }.
            </div>
            <div>
              { `${employee.Surname} ${employee.Name.charAt(0)}.${employee.Patronymic.length ? `${employee.Patronymic.charAt(0)}.` : ''}` }
            </div>
          </div>
          <div className={ styles['reserved-employee__document'] }>
            { passport }
          </div>
        </div>
      );
    });
  };

  render() {
    return (
      <div>
        <div className={ styles['reserved-employees'] }>
          { this.renderEmployeeItem() }
        </div>
      </div>
    );
  }
}

export default EmployeeAir;
