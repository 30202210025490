import PropTypes from 'prop-types';
import React from 'react';

import MoneyFormat from '../../../../../../bi/utils/money';
import { dateUtcFormat, momentSubPeriod } from '../../../../../../bi/utils/formatDate';

import { DAY_OF_MONTH_TIME } from '../../../../../../constants/time';

import styles from './styles/index.module.css';

const LABELS = {
  FROM: 'С',
  BEFORE: 'До',
  CANCELLATION_CONDITION: 'Условия отмены:',
  BEFORE_FREE: cancellationDate => `До ${cancellationDate} (по московскому времени) — бесплатно`,
  FREE_CANCELLATION_WITH_PENALTIES: total => `Отмена бронирования возможна со штрафом ${total} ₽`,
  NON_FREE_CANCELLATION: 'Бесплатная отмена бронирования невозможна',
  MSK_WITH_PENALTIES: total => `(по московскому времени) — со штрафом ${total} ₽`,
};

const MINUTES = 'minutes';

const HotelCancellation = ({ cancellationPenalties, cancellationDate, cancellationInfo }) => {
  const headerConditions = (cancellationPenalties && cancellationPenalties.length > 1)
    ? (
      <div className={ styles['condition-text'] }>
        <b>{LABELS.CANCELLATION_CONDITION}</b>
      </div>
    )
    : null;

  const createItemList = (prefix, from, total, index) => (
    <div key={ `${from}_${index}` } className={ styles.text }>
      {`${prefix} ${dateUtcFormat(from, DAY_OF_MONTH_TIME)} ${LABELS.MSK_WITH_PENALTIES(MoneyFormat.money(total))}`}
    </div>
  );

  let isFreeCancellation = '';
  const penaltyList = [];

  const cancellationNotFromTrip = () => {
    if (!cancellationPenalties) {
      return LABELS.BEFORE_FREE(cancellationDate);
    }

    if (cancellationInfo) {
      cancellationPenalties.forEach(({ From, Total, Additional }, i) => penaltyList.push(!Additional ? createItemList(LABELS.FROM, From, Total, i) : null));

      return LABELS.BEFORE_FREE(cancellationDate);
    }

    isFreeCancellation = cancellationPenalties.length === 1
      ? LABELS.FREE_CANCELLATION_WITH_PENALTIES(cancellationPenalties[0].Total)
      : '';

    if (cancellationPenalties.length > 1) {
      cancellationPenalties.forEach(({ From, Total, Additional }, i) => {
        if (!Additional) {
          if (i === 0) {
            penaltyList.push(createItemList(LABELS.BEFORE, momentSubPeriod(cancellationPenalties[1].From, 1, MINUTES), Total, i));
          } else {
            penaltyList.push(createItemList(LABELS.FROM, From, Total, i));
          }
        } else {
          if (!penaltyList.length) {
            isFreeCancellation = LABELS.NON_FREE_CANCELLATION;
          }
          penaltyList.push(null);
        }
      });
    }

    return isFreeCancellation;
  };

  isFreeCancellation = cancellationNotFromTrip();

  return (
    <div>
      { headerConditions }
      <div className={ styles.text }>
        { isFreeCancellation }
      </div>
      { penaltyList }
    </div>
  );
};

HotelCancellation.propTypes = {
  cancellationPenalties: PropTypes.array.isRequired,
  cancellationDate: PropTypes.string,
  cancellationInfo: PropTypes.string,
};

HotelCancellation.defaultProps = {
  cancellationPenalties: [],
  cancellationDate: null,
  cancellationInfo: null,
};

export { HotelCancellation };
